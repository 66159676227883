import styled from 'styled-components';
import { Text } from '@yandex-lego/components/Text/bundle';

export const Wrapper = styled.div<{ isMobile: boolean }>`
    box-sizing: border-box;
    margin: 0 12px;
    flex-shrink: 0;

    margin-top: ${({ isMobile }) => isMobile && `23px`};
`;

export const FooterText = styled(Text)`
    color: #475a8080;
    text-align: end;
`;

export const TextWrapper = styled.div<{ isMobile: boolean }>`
    border-top: 1px solid #b0bdd633;
    padding: 10px 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: ${({ isMobile }) => (isMobile ? `center` : `space-between`)};
    flex-direction: ${({ isMobile }) => (isMobile ? `column` : `row`)};
    align-items: center;
`;

export const FooterLinks = styled.div<{ isMobile: boolean }>`
    display: flex;
    justify-content: start;
    flex-direction: ${({ isMobile }) => (isMobile ? `column` : `row`)};
    gap: ${({ isMobile }) => (isMobile ? `4px` : `16px`)};
    margin-bottom: ${({ isMobile }) => (isMobile ? `4px` : `0`)};
`;