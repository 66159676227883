import React from 'react';
import { FooterText, TextWrapper, Wrapper, FooterLinks } from './styles';
import { Link } from '@yandex-lego/components/Link';

export interface FooterProps {
    isMobile: boolean;
}

const links = [
    {
        href: 'https://yandex.ru/legal/qr_termsofuse/ru/',
        children: 'Пользовательское соглашение',
    },
    {
        href: 'https://yandex.ru/legal/confidential',
        children: 'Политика конфиденциальности',
    },
];

export const Footer = ({isMobile}: FooterProps) => {
    return (
        <Wrapper isMobile={isMobile}>
            <TextWrapper isMobile={isMobile}>
                <FooterLinks isMobile={isMobile}>
                    {links.map((link) => (
                        <Link key={link.href} href={link.href} target='__blank'>
                            <FooterText typography="body-long-xl">{link.children}</FooterText>
                        </Link>
                    ))}
                </FooterLinks>
                <FooterText typography="body-long-xl">© {new Date().getFullYear()} ООО «Яндекс»</FooterText>
            </TextWrapper>
        </Wrapper>
    );
};
